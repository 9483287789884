import React, { useEffect } from 'react';
import TradeButton from './TradeButton';

const LandingPage = () => {
  useEffect(() => {
    const container = document.getElementById('tradingview-container');
    if (container) {
      container.innerHTML = '';

      const script = document.createElement('script');
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js';
      script.type = 'text/javascript';
      script.async = true;
      script.innerHTML = `
        {
          "autosize": true,
          "symbol": "TSXV:DV",
          "interval": "D",
          "timezone": "Etc/UTC",
          "theme": "light",
          "style": "3",
          "locale": "en",
          "hide_top_toolbar": true,
          "allow_symbol_change": false,
          "details": true,
          "calendar": false,
          "support_host": "https://www.tradingview.com"
        }`;
      container.appendChild(script);
      console.log('TradingView script appended');
    }
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="container mx-auto p-6">
        <header className="flex justify-between items-center py-6">
          <h1 className="text-4xl font-bold text-gray-800">Dolly Varden Silver Corp</h1>
          {/* <nav>
            <a href="#about" className="text-lg text-gray-600 hover:text-gray-900 mx-4">About</a>
            <a href="#chart" className="text-lg text-gray-600 hover:text-gray-900 mx-4">Chart</a>
            <a href="#trade" className="text-lg text-gray-600 hover:text-gray-900 mx-4">Trade</a>
          </nav> */}
        </header>
        <main>
          <section id="about" className="py-20 text-center">
            <h2 className="text-3xl font-semibold text-gray-800">About Dolly Varden Silver Corp</h2>
            <p className="mt-6 text-gray-600">
              Dolly Varden Silver Corporation is a mineral exploration company focused on the exploration and development of the Dolly Varden silver property located in Northwestern British Columbia. The Company is focused on its two high-grade silver deposits: the Dolly Varden deposit and the Torbrit deposit.
            </p>
          </section>
          <section id="chart" className="py-20 text-center">
            <h2 className="text-3xl font-semibold text-gray-800">Stock Chart</h2>
            <div id="tradingview-container" className="mt-6" style={{ height: '400px', width: '100%' }}></div>
          </section>
          <section id="trade" className="py-20 text-center">
            <h2 className="text-3xl font-semibold text-gray-800">Trade Dolly Varden Silver Corp</h2>
            <TradeButton ticker="DV.V" />
          </section>
        </main>
        <footer className="py-6 text-center text-gray-600">
          © {new Date().getFullYear()} Dolly Varden Silver Corp. All rights reserved.
        </footer>
      </div>
    </div>
  );
};


export default LandingPage;
