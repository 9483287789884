import React, { useState } from 'react';

const TradeButton = ({ ticker }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const handleSignUp = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${backendUrl}/signup`);
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('userId', data.userId);
        localStorage.setItem('userSecret', data.userSecret);
        console.log('Redirecting to:', data.redirectURI);
        window.location.href = data.redirectURI;
      } else {
        throw new Error('Failed to sign up');
      }
    } catch (error) {
      console.error('Error during sign-up:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mt-8">
      <button
        onClick={handleSignUp}
        className="px-10 py-4 bg-blue-600 text-white text-xl font-semibold rounded-md shadow-md hover:bg-blue-700 transition duration-300"
        disabled={loading}
      >
        {loading ? 'Loading...' : 'Invest Now'}
      </button>
      {error && <p className="text-red-500 mt-4">{error}</p>}
    </div>
  );
};

export default TradeButton;
