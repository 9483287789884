import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import OrderModal from '../components/OrderModal';
import LoadingScreen from './LoadingScreen';

// Load backend URL from environment variables
const backendUrl = process.env.REACT_APP_BACKEND_URL;

// Custom hook to parse query parameters
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const PostAuthorization = () => {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stockPrice, setStockPrice] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderDetails, setOrderDetails] = useState({ orderType: 'Market', quantity: 1, limitPrice: '' });
  const [companyName, setCompanyName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const query = useQuery();

  // CHANGE BACK FOR PRODUCTION VERSION For testing purposes I put in a fixed userId and Secret so that there are no errors during testing.
  const userId = query.get('userId'); // 'user-1721242383553-4leop6qhl';
  const userSecret = query.get('userSecret'); // 'e623e169-ec07-46cc-881d-becc962077f8'; // 

  useEffect(() => {
    if (!userId || !userSecret) {
      setError('Invalid userId or userSecret');
      setLoading(false);
      return;
    }

  const fetchAccounts = async () => {
    try {
      const response = await fetch(`${backendUrl}/accounts?userId=${userId}&userSecret=${userSecret}`);
      if (!response.ok) throw new Error('Failed to fetch accounts');
      const data = await response.json();
      setAccounts(data.filter(account => !account.meta?.type.includes('crypto')));
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  fetchAccounts();
}, [userId, userSecret]);

useEffect(() => {
  const container = document.getElementById('tradingview-container');
  if (container) {
    container.innerHTML = '';

    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js';
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
      {
        "autosize": true,
        "symbol": "TSXV:DV",
        "interval": "D",
        "timezone": "Etc/UTC",
        "theme": "light",
        "style": "3",
        "locale": "en",
        "hide_top_toolbar": true,
        "allow_symbol_change": false,
        "details": true,
        "calendar": false,
        "support_host": "https://www.tradingview.com"
      }`;
    container.appendChild(script);
    console.log('TradingView script appended');
  }
}, []);

  const handleSelectAccount = async (accountId) => {
    setIsLoading(true);
    setSelectedAccount(accountId);
    try {
      const symbolResponse = await fetch(`${backendUrl}/find-symbol?userId=${userId}&userSecret=${userSecret}&ticker=DV.V&exchange=TSXV&accountId=${accountId}`);
      if (!symbolResponse.ok) throw new Error('Failed to fetch symbol');
      const symbolData = await symbolResponse.json();
      const universalSymbolId = symbolData.id;

      setCompanyName(symbolData.description);

      const stockResponse = await fetch(`${backendUrl}/stock-price?userId=${userId}&userSecret=${userSecret}&accountId=${accountId}&universalSymbolId=${universalSymbolId}`);
      if (!stockResponse.ok) throw new Error('Failed to fetch stock price');
      const stockData = await stockResponse.json();
      setStockPrice(stockData[0]?.last_trade_price);
      setIsModalOpen(true);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBuyStock = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${backendUrl}/buy`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId,
          userSecret,
          accountId: selectedAccount,
          ticker: 'DV.VN',
          quantity: orderDetails.quantity,
          orderType: orderDetails.orderType,
          limitPrice: orderDetails.orderType === 'Limit' ? orderDetails.limitPrice : undefined,
        }),
      });

      if (!response.ok) throw new Error('Failed to place order');

      const data = await response.json();
      setIsModalOpen(false);
      return true;
    } catch (err) {
      setError(err.message);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  if (loading) return <LoadingScreen />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto p-4">
      {isLoading && <LoadingScreen />}
      <h1 className="text-3xl font-bold mb-6">Account Balance</h1>
        {/* <section id="chart" className="py-20 text-center">
            <div id="tradingview-container" className="mt-6" style={{ height: '400px', width: '100%' }}></div>
        </section> */}
        {accounts.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {accounts.map((account) => (
            <div
              key={account.id}
              className="p-4 bg-gray-100 rounded-lg shadow-md hover:bg-gray-200 cursor-pointer transition duration-300"
              onClick={() => handleSelectAccount(account.id)}
            >
              <h2 className="text-xl font-bold mb-2">{account.name.replace('Wealthsimple Trade', '')}</h2>
              <p className="text-gray-600">{account.balance?.total?.currency || 'N/A'}</p>
              <p className="text-gray-600">
                Buying Power: ${account.balance?.total?.amount ? account.balance.total.amount.toFixed(2) : '0.00'} {account.balance?.total?.currency || 'N/A'}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <div>No accounts data available</div>
      )}
      <OrderModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        stockPrice={stockPrice}
        symbolInfo={{ name: companyName, ticker: 'DV.V', currency: 'CAD' }}
        orderDetails={orderDetails}
        setOrderDetails={setOrderDetails}
        handleBuyStock={handleBuyStock}
      />
    </div>
  );
};

export default PostAuthorization;