import React from 'react';
import { useLocation } from 'react-router-dom';

const OrderConfirmation = () => {
  const location = useLocation();
  const { stockPrice, symbolInfo, orderDetails } = location.state || {};

  if (!orderDetails) {
    return <div className="text-red-500 text-center text-lg mt-20">Error: Missing order details</div>;
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Order Confirmation</h1>
        <div className="text-lg mb-4">
          <span className="font-semibold">Stock:</span> {symbolInfo.name} ({symbolInfo.ticker}) - {symbolInfo.currency}
        </div>
        <div className="text-lg mb-4">
          <span className="font-semibold">Price:</span> {stockPrice} CAD
        </div>
        <div className="text-lg mb-4">
          <span className="font-semibold">Order Type:</span> {orderDetails.orderType}
        </div>
        {orderDetails.orderType === 'Limit' && (
          <div className="text-lg mb-4">
            <span className="font-semibold">Limit Price:</span> {orderDetails.limitPrice}
          </div>
        )}
        <div className="text-lg mb-4">
          <span className="font-semibold">Quantity:</span> {orderDetails.quantity}
        </div>
        <div className="text-lg mb-4">
          <span className="font-semibold">Total Cost:</span> {(orderDetails.quantity * stockPrice).toFixed(2)} CAD
        </div>
        <div className="text-center mt-6">
          <button
            onClick={() => window.location.href = '/'} // Redirect to homepage or another page
            className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300"
          >
            Return to Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmation;
