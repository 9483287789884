import React from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';

const OrderModal = ({ isOpen, onRequestClose, stockPrice, symbolInfo, orderDetails, setOrderDetails, handleBuyStock }) => {
  const navigate = useNavigate();

  const handlePlaceOrder = async () => {
    const success = await handleBuyStock();
    if (success) {
      navigate('/confirmation', { state: { stockPrice, symbolInfo, orderDetails } });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Place Order"
      className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg mx-auto mt-20"
      overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center"
    >
      <h2 className="text-2xl font-semibold mb-4">Place Order</h2>
      <p className="text-lg mb-4">{symbolInfo.name} ({symbolInfo.ticker}) - {symbolInfo.currency}</p>
      <p className="text-lg mb-2">Current Price: <span className="font-semibold">{stockPrice} CAD</span></p>
      <div className="mb-4">
        <label className="block text-gray-700 mb-2">Order Type:</label>
        <select
          value={orderDetails.orderType}
          onChange={(e) => setOrderDetails({ ...orderDetails, orderType: e.target.value })}
          className="w-full px-3 py-2 border rounded-lg"
        >
          <option value="Market">Market</option>
          <option value="Limit">Limit</option>
        </select>
      </div>
      {orderDetails.orderType === 'Limit' && (
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Limit Price:</label>
          <input
            type="number"
            value={orderDetails.limitPrice}
            onChange={(e) => setOrderDetails({ ...orderDetails, limitPrice: e.target.value })}
            className="w-full px-3 py-2 border rounded-lg"
          />
        </div>
      )}
      <div className="mb-4">
        <label className="block text-gray-700 mb-2">Quantity:</label>
        <input
          type="number"
          value={orderDetails.quantity}
          onChange={(e) => setOrderDetails({ ...orderDetails, quantity: e.target.value })}
          className="w-full px-3 py-2 border rounded-lg"
          min="1"
        />
      </div>
      <div className="flex justify-end space-x-4">
        <button onClick={onRequestClose} className="px-4 py-2 bg-gray-300 rounded-lg">Cancel</button>
        <button onClick={handlePlaceOrder} className="px-4 py-2 bg-blue-500 text-white rounded-lg">Place Order</button>
      </div>
    </Modal>
  );
};

export default OrderModal;
